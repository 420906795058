import React, { useEffect, useState } from "react";
import Data from '../data/data.json';
import IconUp from '../assets/images/icon-up.svg'

export const Projects = () => {
  //const [projectsView, setProjectsView] = useState(false);
  const [userData, setUserData] = useState('');

  useEffect(() => {
      setUserData(Data);
  },[userData]);

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
  /* const handleView = () => {
    setProjectsView(projectsView ? false : true);
    console.log(projectsView);
  } */

  return (
    <section className="projects">
      {/* <button type="submit" onClick={handleView} className="btn-projects">
        <img src={projectsView ? IconDown : IconUp} alt="Projetcs" />
      </button> */}
      <h2>Projects</h2>
      <article className="content">
        <table>
          <tbody>
            {userData?.projects?.map((project) => {
              return(
                <tr key={project.id} >
                  <td>{project.project}</td>
                  <td>{project.country}</td>
                  <td>{project.tech}</td>
                  <td>{project.year}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </article>
      <button onClick={handleScrollTop} className="btn-top"><img src={IconUp} alt="back to top" /></button>
    </section>
  );
}