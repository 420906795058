import React, { useEffect, useState } from "react";
import Data from '../data/data.json';

export const UsersDetails = () => {
    const [userData, setUserData] = useState('');

    useEffect(() => {
        setUserData(Data);
    },[userData]);

    return(
        <section className="main">
            <header>
                <h1>{userData?.name}</h1>
                <h3>{userData?.role}</h3>
            </header>
            
            <ul className="skills">
                {userData?.skills?.map((skills) => {
                    return <li key={skills}>{skills}</li>
                })}
            </ul>

            <footer>
                {userData?.social?.map((social) => {
                    return <a key={social.id} href={social.url} target="_blank" rel="noreferrer"><img src={'images/' + social.filename} width="48" alt={social.website} /></a>
                })}
            </footer>
        </section>
    )
}