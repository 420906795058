import './assets/css/style.css';
import React from 'react';
import { UsersDetails } from './components/userData';
import { Projects } from './components/projects';

function App() {

  return (
    <div className="App">
      <UsersDetails />
      <Projects />

      <div className="overlay"></div>
    </div>
  );
}

export default App;
